<template>
  <div class="discount">
    <div style="height: 220px; margin: 10px 0px">
      图片
    </div>
    <div class="top-discount">
      {{ couponInfo.goodsName }}
    </div>
    <div>
      <p style="padding: 10px" class="biaoti">商品图片</p>
      <img :src="couponInfo.productPicture" alt="图片" style="width: 100%" />
    </div>
    <div class="center-discount" style="padding-bottom: 100px">
      <p class="biaoti">商品详情</p>
      <p class="xiangqing">
        {{ couponInfo.goodsDesc }}
      </p>
    </div>
    <div class="bottom-discount" v-if="!duihuanSuccess">
      <button @click="duihuan">积分兑换</button>
    </div>
    <div class="bottom-discount" v-else>
      <button style="background: #999">已兑换</button>
    </div>
  </div>
</template>
<script>
import { jifen } from '../../utils/apis/apis'
export default {
  name: 'login',
  data() {
    return {
      id: '',
      couponInfo: {},
      duihuanSuccess: false
    }
  },

  created() {
    this.id = this.$route.params.id
    this.getData()
  },
  methods: {
    getData() {
      this.$reqGet(jifen.item + this.id).then((res) => {
        if (res.data.code === 200) {
          this.couponInfo = res.data.data
        }
      })
    },
    duihuan() {
      this.$confirm({
        title: '确认',
        content: '确定要兑换吗？',
        okFn: () => {
          this.$reqPost(jifen.duihuan, {
            memberId: JSON.parse(localStorage.userInfo).id,
            goodsId: this.id
          }).then((res) => {
            if (res.data.code === 200) {
              this.$toast.success('兑换成功')
              this.duihuanSuccess = true
            } else {
              this.$toast.success(res.data.message)
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import url("../order-management/order.less");
.biaoti {
  font-weight: bold;
}
.xiangqing {
  font-size: 14px;
  margin-top: 10px;
}
</style>
